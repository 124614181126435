@charset "utf-8";

@import "variables";




#slides-wrap{
	overflow: hidden;
	position: relative;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	#slides{
		.slides-item{
			position: relative;
			color: #fff;
			background-position: top center;
			background-repeat: no-repeat;	
			// background-attachment: fixed;
			.slides-info{
				position: absolute;
				top: calc(50% - 32.5px);
				left: 50%;
				width: 100%;
				padding: 15px;
				box-sizing: border-box;
				transform: translate(-50%, -50%);
				opacity: 0;
				margin-top: 50px;
				transition: opacity 1s .3s,
							margin 1s .3s;
				.slides-meta{
					color: #eee;
					font-style: italic;
				}
				h3{
					font-size: 16px;
					line-height: 1.8;
					font-family: $fontJa;
					font-weight: 600;
				}
				.more{
					display: inline-block;
					background-color: #019DD6;
					padding: 6px 30px;
					margin: 20px 0 0;
				}
			}
			a.permalink{
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		.slides-item:before{
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			background-color: rgba(0,0,0,0.5);
		}
		.slides-item.slick-current{
			.slides-info{
				opacity: 1;
				margin-top: 0;
			}
		}
	}
	ul#slides-nav{
		position: absolute;
		bottom: 10px;
		right: 0;
		z-index: 1;
		line-height: 0;
		li{
			cursor: pointer;
			display: inline-block;
			margin-right: 10px;
			span{
				position: relative;
				overflow: hidden;
				display: block;
				width: 60px;
				height: 0;
				padding-top: 100%;
				border: 2px solid #fff;
				img{
					position: absolute;
					top: 50%;
					left: 50%;
					width: auto;
					height: 100%;
					transform: translate(-50%, -50%);
				}
			}
		}
	}

	.slides-item-in{
		position: absolute;
		// top: calc(50% - 32.5px);
		top: 50%;
		left: 50%;
		z-index: 1;
		width: 100%;
		// box-sizing: border-box;
		transform: translate(-50%, -50%);
		.slides-caption{
			h2{
				white-space: nowrap;
				text-align: center;
				color: $red;
				font-size: 8.7vw;
				line-height: 1.2;
				font-family: $fontJa;
				font-weight: 300;
				letter-spacing: -.1em;
				margin: 0 0 20px;
				span{
					margin: 0 -.4em;
				}
			}
			p{
				color: #fff;
				padding: 0 15px;
			}
		}
		.slides-ribons{
			margin: 0 0 10px;
			span{
				display: inline-block;
				color: #fff;
				background-color: #D2001E;
				padding: 3px 15px;
				margin: 0 0 10px; 
			}
		}
	}
}

#wrap{
	// position: absolute;
	// top: 100vh;
	// left: 0;
}

#container{
/*
	overflow: hidden;
	padding: 5px;
	margin: 0 -10px;
	box-sizing: border-box;
*/
}
#performance{
	margin: 2.5px;
	.grid-sizer,
	.post{
		width: 50%;
		padding: 2.5px;
	}
	.post.rec-post{
		width: 50%;
	}
	.post{
		.post-in{
			overflow: hidden;
			position: relative;
			// transition: all .5s;
			.post-image{
				line-height: 0;
				span{
					overflow: hidden;
					position: relative;
					display: block;
					width: 100%;
					height: 0;
					padding-top: 85%;
					img{
						position: absolute;
						top: 50%;
						left: 50%;
						width: 130%;
						height: auto;
						transform: translate(-50%, -50%);
						transition: all 2s;
					}
					iframe,
					.player{
						position: absolute;
						top: 50%;
						left: 50%;
						// width: 135%;
						// height: 135%;
						width: 180%;
						height: 180%;
						transform: translate(-50%, -50%);
					}
				}
			}
			.post-image.movie{
				position: relative;
			}
			.post-image.movie:before{
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 1;
				width: 100%;
				height: 100%;
				background: url(../img/common/dot.png);
			}
			.post-cat{
				// opacity: 0;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 3;
				color: #fff;
				font-size: 12px;
				background-color: rgba(0,0,0,.9);
				padding: 3px 10px;
			}
			.post-detail{
				display: none;
			}
			.post-overlay{
				display: block;
				width: 100%;
				height: 100%;
				position: absolute;
				z-index: 2;
				opacity: 0.8;
				// background-color: rgba(0,0,0,0.6);
			}
			a.permalink{
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				z-index: 4;
				width: 100%;
				height: 100%;
				padding: 0;
			}
		}

		.post-in:hover{
			.post-image{
				span{
					img{
						transform: translate(-50%, -50%) scale(1.2);
					}
				}
			}
		}
	}
}

#news{
	padding: 40px 0 60px;
	h2{
		font-size: 20px;
		font-family: $fontJa;
		font-weight: 600;
		margin: 0 0 -10px;
		i{
			// color: #ffa500;
			font-size: 1.2em;
			margin-right: 5px;
		}
		small{
			display: inline-block;
			color: #9d9d9d;
			font-size: 13px;
			font-family: $fontBase;
			font-weight: normal;
			margin-left: 15px;
		}
	}
	.articles{
		letter-spacing: -.4em;
		article{
			letter-spacing: normal;
			position: relative;
			margin: 25px 0 0;
			// border-top: 1px dotted #ccc;
			.art-date{
				color: #9d9d9d;
				font-style: italic;
			}
			h3{
				font-size: 14px;
				text-decoration: underline;
				padding: 0 0 0 1em;
			}
			p{
				color: #666;
				padding: 0 0 0 1em;
				margin: 5px 0 0;
			}
			a{
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
			}
		}
		article:hover{
			h3{
				text-decoration: none;
			}
		}
	}
}


@media screen and (min-width: 600px) {
	#performance{
		.post{
			.post-in{
				.post-cat{
					letter-spacing: .5px;
					padding: 5px 15px;
				}
				.post-detail{
					display: block;
					opacity: 0;
					position: absolute;
					top: 60%;
					left: 0;
					z-index: 3;
					width: 100%;
					color: #fff;
					text-align: center;
					line-height: 1.8;
					padding: 0 15px;
					box-sizing: border-box;
					transform: translateY(-50%);
					transition: all .5s;
					.post-date{
						color: #eee;
						font-size: 13px;
						font-style: italic;
						// letter-spacing: 1px;
					}
					h4{
						font-size: 15px;
						font-family: $fontJa;
						font-weight: 600;
					}
				}
			}
			.post-in:hover{
				.post-detail{
					top: 50%;
					opacity: 1;
				}
			}
		}
	}

	#news{
		h2{
			font-size: 22px;
			margin: 0 0 -5px;
		}
		.articles{
			article{
				padding: 5px 0 5px 20px;
			}
			article:before{
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 5px;
				height: 100%;
				background-color: #eee;
			}
			article:after{
				content: '';
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				width: 5px;
				height: 0;
				background-color: #000;
				transition: height .3s;
			}
			article:hover:after{
				height: 100%;
			}
		}
	}

}


@media screen and (min-width: 800px) {
	#slides-wrap{
		left: 240px;
		width: calc(100% - 240px);
		// border: 15px solid #fff;
		box-sizing: border-box;
		#slides{
			.slides-item{
				.slides-info{
					top: 5%;
					left: 5%;
					width: 80%;
					padding: 0;
					transform: translate(0,0);
					h3{
						font-size: 18px;
					}
				}
			}
			.slides-item:before{
				// background-color: rgba(0,0,0,0.4);
			}
		}
		.slides-item-in{
			// position: relative;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			transform: translate(0, 0);
			.slides-caption{
				position: absolute;
				top: 50%;
				left: 50%;
				width: 100%;
				box-sizing: border-box;
				transform: translate(-50%, -50%);
				h2{
					font-size: 6.3vw;
					font-weight: 200;
				}
				p{
					margin: 0 6%;
				}
			}
			.slides-ribons{
				span{
					position: absolute;
					top: 0;
					right: 0;
					padding: 0;
					margin: 0;
					transform: translateY(-100%) rotate(-25deg);
					transform-origin: top right;
					padding: 3px 80px 3px 15px;
				}
				span:first-of-type{
					right: 110px;
				}
			}
		}
	}

	#performance{
		margin: 5px -5px;
		.grid-sizer,
		.post{
			padding: 5px;
		}
	}
	
}


@media screen and (min-width: 1000px) {
	#slides-wrap{
		#slides{
			.slides-item{
				.slides-info{
					width: 70%;
					h3{
						font-size: 20px;
					}
				}
			}
		}
		.slides-item-in{
			.slides-caption{
				p{
					font-size: 14px;
				}
			}
			.slides-ribons{
				span{
					font-size: 15px;
				}
				span:first-of-type{
					right: 120px;
				}
			}
		}
	}

	#news{
		.inner{
			overflow: hidden;
		}
		h2{
			float: left;
			font-size: 24px;
			width: 200px;
			margin: 0;
			small{
				display: block;
				margin: -5px 0 0;
			}
		}
		.articles{
			margin-left: 230px;
			article:first-of-type{
				margin: 0;
			}
		}
	}


}


@media screen and (min-width: 1200px) {
	#slides-wrap{
		#slides{
			.slides-item{
				.slides-info{
					width: 60%;
					h3{
						font-size: 24px;
					}
				}
			}
		}
		.slides-item-in{
			.slides-caption{
				h2{
					font-size: 7.4vw;
				}
			}
			.slides-ribons{
				span{
					font-size: 16px;
				}
				span:first-of-type{
					right: 130px;
				}
			}
		}
	}

	#performance{
		.grid-sizer,
		.post{
			width: 25%;
		}
		.post.rec-post{
			width: 50%;
			.post-in{
				.post-image.movie{
					span{
						padding-top: 85.28%; // +0.28%
					}
				}
			}
		}
	}

	#news{
		h2{
			font-size: 26px;
		}
	}
}


@media screen and (min-width: 1600px) {

	#performance{
		.grid-sizer,
		.post{
			width: 20%;
		}
		.post.rec-post{
			width: 40%;
		}
	}
}
/* colors */
/* font-family */
/* easing */
#slides-wrap {
  overflow: hidden;
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#slides-wrap #slides .slides-item {
  position: relative;
  color: #fff;
  background-position: top center;
  background-repeat: no-repeat;
}

#slides-wrap #slides .slides-item .slides-info {
  position: absolute;
  top: calc(50% - 32.5px);
  left: 50%;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  transform: translate(-50%, -50%);
  opacity: 0;
  margin-top: 50px;
  transition: opacity 1s .3s, margin 1s .3s;
}

#slides-wrap #slides .slides-item .slides-info .slides-meta {
  color: #eee;
  font-style: italic;
}

#slides-wrap #slides .slides-item .slides-info h3 {
  font-size: 16px;
  line-height: 1.8;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
}

#slides-wrap #slides .slides-item .slides-info .more {
  display: inline-block;
  background-color: #019DD6;
  padding: 6px 30px;
  margin: 20px 0 0;
}

#slides-wrap #slides .slides-item a.permalink {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#slides-wrap #slides .slides-item:before {
  content: '';
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

#slides-wrap #slides .slides-item.slick-current .slides-info {
  opacity: 1;
  margin-top: 0;
}

#slides-wrap ul#slides-nav {
  position: absolute;
  bottom: 10px;
  right: 0;
  z-index: 1;
  line-height: 0;
}

#slides-wrap ul#slides-nav li {
  cursor: pointer;
  display: inline-block;
  margin-right: 10px;
}

#slides-wrap ul#slides-nav li span {
  position: relative;
  overflow: hidden;
  display: block;
  width: 60px;
  height: 0;
  padding-top: 100%;
  border: 2px solid #fff;
}

#slides-wrap ul#slides-nav li span img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  height: 100%;
  transform: translate(-50%, -50%);
}

#slides-wrap .slides-item-in {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1;
  width: 100%;
  transform: translate(-50%, -50%);
}

#slides-wrap .slides-item-in .slides-caption h2 {
  white-space: nowrap;
  text-align: center;
  color: #D2001E;
  font-size: 8.7vw;
  line-height: 1.2;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 300;
  letter-spacing: -.1em;
  margin: 0 0 20px;
}

#slides-wrap .slides-item-in .slides-caption h2 span {
  margin: 0 -.4em;
}

#slides-wrap .slides-item-in .slides-caption p {
  color: #fff;
  padding: 0 15px;
}

#slides-wrap .slides-item-in .slides-ribons {
  margin: 0 0 10px;
}

#slides-wrap .slides-item-in .slides-ribons span {
  display: inline-block;
  color: #fff;
  background-color: #D2001E;
  padding: 3px 15px;
  margin: 0 0 10px;
}

#container {
  /*
	overflow: hidden;
	padding: 5px;
	margin: 0 -10px;
	box-sizing: border-box;
*/
}

#performance {
  margin: 2.5px;
}

#performance .grid-sizer,
#performance .post {
  width: 50%;
  padding: 2.5px;
}

#performance .post.rec-post {
  width: 50%;
}

#performance .post .post-in {
  overflow: hidden;
  position: relative;
}

#performance .post .post-in .post-image {
  line-height: 0;
}

#performance .post .post-in .post-image span {
  overflow: hidden;
  position: relative;
  display: block;
  width: 100%;
  height: 0;
  padding-top: 85%;
}

#performance .post .post-in .post-image span img {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 130%;
  height: auto;
  transform: translate(-50%, -50%);
  transition: all 2s;
}

#performance .post .post-in .post-image span iframe,
#performance .post .post-in .post-image span .player {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 180%;
  height: 180%;
  transform: translate(-50%, -50%);
}

#performance .post .post-in .post-image.movie {
  position: relative;
}

#performance .post .post-in .post-image.movie:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  background: url(../img/common/dot.png);
}

#performance .post .post-in .post-cat {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  color: #fff;
  font-size: 12px;
  background-color: rgba(0, 0, 0, 0.9);
  padding: 3px 10px;
}

#performance .post .post-in .post-detail {
  display: none;
}

#performance .post .post-in .post-overlay {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 2;
  opacity: 0.8;
}

#performance .post .post-in a.permalink {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4;
  width: 100%;
  height: 100%;
  padding: 0;
}

#performance .post .post-in:hover .post-image span img {
  transform: translate(-50%, -50%) scale(1.2);
}

#news {
  padding: 40px 0 60px;
}

#news h2 {
  font-size: 20px;
  font-family: "Noto Sans Japanese", serif;
  font-weight: 600;
  margin: 0 0 -10px;
}

#news h2 i {
  font-size: 1.2em;
  margin-right: 5px;
}

#news h2 small {
  display: inline-block;
  color: #9d9d9d;
  font-size: 13px;
  font-family: "Open Sans", YuGothic, "Hiragino Kaku Gothic Pro", "Meiryo", sans-serif;
  font-weight: normal;
  margin-left: 15px;
}

#news .articles {
  letter-spacing: -.4em;
}

#news .articles article {
  letter-spacing: normal;
  position: relative;
  margin: 25px 0 0;
}

#news .articles article .art-date {
  color: #9d9d9d;
  font-style: italic;
}

#news .articles article h3 {
  font-size: 14px;
  text-decoration: underline;
  padding: 0 0 0 1em;
}

#news .articles article p {
  color: #666;
  padding: 0 0 0 1em;
  margin: 5px 0 0;
}

#news .articles article a {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

#news .articles article:hover h3 {
  text-decoration: none;
}

@media screen and (min-width: 600px) {
  #performance .post .post-in .post-cat {
    letter-spacing: .5px;
    padding: 5px 15px;
  }
  #performance .post .post-in .post-detail {
    display: block;
    opacity: 0;
    position: absolute;
    top: 60%;
    left: 0;
    z-index: 3;
    width: 100%;
    color: #fff;
    text-align: center;
    line-height: 1.8;
    padding: 0 15px;
    box-sizing: border-box;
    transform: translateY(-50%);
    transition: all .5s;
  }
  #performance .post .post-in .post-detail .post-date {
    color: #eee;
    font-size: 13px;
    font-style: italic;
  }
  #performance .post .post-in .post-detail h4 {
    font-size: 15px;
    font-family: "Noto Sans Japanese", serif;
    font-weight: 600;
  }
  #performance .post .post-in:hover .post-detail {
    top: 50%;
    opacity: 1;
  }
  #news h2 {
    font-size: 22px;
    margin: 0 0 -5px;
  }
  #news .articles article {
    padding: 5px 0 5px 20px;
  }
  #news .articles article:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 100%;
    background-color: #eee;
  }
  #news .articles article:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 5px;
    height: 0;
    background-color: #000;
    transition: height .3s;
  }
  #news .articles article:hover:after {
    height: 100%;
  }
}

@media screen and (min-width: 800px) {
  #slides-wrap {
    left: 240px;
    width: calc(100% - 240px);
    box-sizing: border-box;
  }
  #slides-wrap #slides .slides-item .slides-info {
    top: 5%;
    left: 5%;
    width: 80%;
    padding: 0;
    transform: translate(0, 0);
  }
  #slides-wrap #slides .slides-item .slides-info h3 {
    font-size: 18px;
  }
  #slides-wrap .slides-item-in {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transform: translate(0, 0);
  }
  #slides-wrap .slides-item-in .slides-caption {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 100%;
    box-sizing: border-box;
    transform: translate(-50%, -50%);
  }
  #slides-wrap .slides-item-in .slides-caption h2 {
    font-size: 6.3vw;
    font-weight: 200;
  }
  #slides-wrap .slides-item-in .slides-caption p {
    margin: 0 6%;
  }
  #slides-wrap .slides-item-in .slides-ribons span {
    position: absolute;
    top: 0;
    right: 0;
    padding: 0;
    margin: 0;
    transform: translateY(-100%) rotate(-25deg);
    transform-origin: top right;
    padding: 3px 80px 3px 15px;
  }
  #slides-wrap .slides-item-in .slides-ribons span:first-of-type {
    right: 110px;
  }
  #performance {
    margin: 5px -5px;
  }
  #performance .grid-sizer,
  #performance .post {
    padding: 5px;
  }
}

@media screen and (min-width: 1000px) {
  #slides-wrap #slides .slides-item .slides-info {
    width: 70%;
  }
  #slides-wrap #slides .slides-item .slides-info h3 {
    font-size: 20px;
  }
  #slides-wrap .slides-item-in .slides-caption p {
    font-size: 14px;
  }
  #slides-wrap .slides-item-in .slides-ribons span {
    font-size: 15px;
  }
  #slides-wrap .slides-item-in .slides-ribons span:first-of-type {
    right: 120px;
  }
  #news .inner {
    overflow: hidden;
  }
  #news h2 {
    float: left;
    font-size: 24px;
    width: 200px;
    margin: 0;
  }
  #news h2 small {
    display: block;
    margin: -5px 0 0;
  }
  #news .articles {
    margin-left: 230px;
  }
  #news .articles article:first-of-type {
    margin: 0;
  }
}

@media screen and (min-width: 1200px) {
  #slides-wrap #slides .slides-item .slides-info {
    width: 60%;
  }
  #slides-wrap #slides .slides-item .slides-info h3 {
    font-size: 24px;
  }
  #slides-wrap .slides-item-in .slides-caption h2 {
    font-size: 7.4vw;
  }
  #slides-wrap .slides-item-in .slides-ribons span {
    font-size: 16px;
  }
  #slides-wrap .slides-item-in .slides-ribons span:first-of-type {
    right: 130px;
  }
  #performance .grid-sizer,
  #performance .post {
    width: 25%;
  }
  #performance .post.rec-post {
    width: 50%;
  }
  #performance .post.rec-post .post-in .post-image.movie span {
    padding-top: 85.28%;
  }
  #news h2 {
    font-size: 26px;
  }
}

@media screen and (min-width: 1600px) {
  #performance .grid-sizer,
  #performance .post {
    width: 20%;
  }
  #performance .post.rec-post {
    width: 40%;
  }
}
